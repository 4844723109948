import React from 'react';

type Props = {
  scrollY: number;
};

export const IwatsuhimeJinja = ({ scrollY }: Props) => {
  return (
    <>
      <div className="area js-area">
        {/* <img src="/images/myoujin-white.png" alt='about-jinja' className="text-myoujin"/> */}
        <div className="wrap js-wrap">
          <div className="item-1">
            {/* アイテム1の内容 */}
            <h2>自由参拝の流れ</h2>
            <div className='p-1'>
              <p className='sanpai-ja'>参道の手水舎（てみずしゃ）にて手と口を清め</p>
              <p className='sanpai-eg'>The temizusya is a place where hands and mouth are purified with water before reaching the place of worship.</p>
              <img src='/images/IMG_0868.jpg' alt='img' className='sanpai-img1'/>
            </div>
          </div>
          <div className="item-1">
            {/* アイテム2の内容 */}
            <div className='p-2'>
              <img src='/images/IMG_0846.jpg' alt='img' className='sanpai-img2'/>
              <p className='sanpai-ja'>拝殿前まで進み軽くお辞儀（15度の角度）</p>
              <p className='sanpai-eg'>Advance to the front of the main hall and bow slightly (at a 15-degree angle).</p>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム3の内容 */}
            <div className='p-3'>
              <p className='sanpai-ja'>二礼・※二拍手・一礼（再拝・二拍手・一拝）</p>
              <p className='sanpai-eg'>Two bows, two claps, and one bow (to repeat the worship, two claps, and one bow).</p>
              <img src='/images/IMG_0847.jpg' alt='img' className='sanpai-img1'/>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム4の内容 */}
            <div className='p-4'>
            <p className='sanpai-ja'>一礼の後、軽くお辞儀（15度の角度）をして退く。</p>
            <p className='sanpai-eg'>After the final deep bow, give a light bow at a 15-degree angle before stepping back.</p>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* 棒線 */}
            <div className='bousen-1'>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム5の内容 */}
            <div className='p-5'>
              <img src="/images/touka01.png" alt='about-jinja' className=""/>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* 棒線 */}
            <div className='bousen-2'>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム4の内容 */}
            <div className='p-6'>
            <p className='sanpai-ja'>みさきみょうじん</p>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム7の内容 */}
            <div className='p-7'>
              <img src="/images/yuisyo.png" alt='about-jinja'/>
            </div>
          </div>
          <div className="item-1 js-item">
            {/* アイテム7の内容 */}
            <div className='p-8'>
              <img src="/images/icon.png" alt='about-jinja'/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}