import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './style/App.scss';
import Home from './components/Home';
import Header from './components/Header';
import { News } from './components/News';
import { Sanpai } from './components/Sanpai';
import { IwatsuhimeJinja } from './components/IwatsuhimeJinja';
import { SaijiAndShinji } from './components/Event';
import { Access } from './components/Access';
import { Footer } from './components/Footer';
import { NextEvent } from './components/NextEvent';
import { NextOmamori } from './components/NextOmamori';
import { NextGokitou } from './components/NextGokitou';
import { NextNews } from './components/NextNews';
import { useScrollPosition } from './hooks/useScrollPosition';
import Caution from './components/Caution';

function App() {
  const scrollY = useScrollPosition();
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Home scrollY={scrollY}/>
              <News scrollY={scrollY}/>
              <Sanpai scrollY={scrollY}/>
              <IwatsuhimeJinja scrollY={scrollY}/>
              <SaijiAndShinji scrollY={scrollY}/>
              <Access scrollY={scrollY}/>
              <Footer />
            </>
          } />
          <Route path="/next-event" element={<NextEvent />} />
          <Route path="/omamori" element={<NextOmamori />} />
          <Route path="/Gokitou" element={<NextGokitou />} />
          <Route path="/news" element={<NextNews />} />
          <Route path="/caution" element={<Caution />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;