import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLElement | null>(null);
  const hamburgerRef = useRef<HTMLButtonElement | null>(null);

  const handleOnClick = () => {
    setIsActive(!isActive);
    setIsMenuVisible(!isMenuVisible);
    
  };
  const navigate = useNavigate();
  const clickToHome = () => {
    navigate('/');
  };
  const clickToEvent = () => {
    navigate('/next-event');
  };
  const clickToOmamori = () => {
    navigate('/omamori');
  };
  const clickToGokitou = () => {
    navigate('/gokitou');
  };
  const clickToCaution = () => {
    navigate('/caution');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && hamburgerRef.current && !menuRef.current.contains(event.target as Node) && !hamburgerRef.current.contains(event.target as Node)) {
      setIsActive(false);
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // イベントリスナーをクリーンアップ
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
    <div className={`header`}>
    <button ref={hamburgerRef} onClick={handleOnClick} className={`openbtn8 ${isActive ? 'active' : ''}`}>
      <div className="openbtn-area">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
      <div className='header-icon-dev'>
        <img src="/images/icon.png" alt="icon" className="header-icon" />
      </div>
      <p>Iwatsuhime Jinja (Shinto Shrine)</p>
      <a href='https://www.instagram.com/iwatsuhime_shrine/'>
       <img src="/svg/ico_insta.svg" alt="icon" className="insta-icon" />
      </a>
    </div>
    {isMenuVisible && (
  <nav ref={menuRef} className={`menu-list ${isMenuVisible ? 'active' : ''}`}>
    <ul>
      <li><a onClick={clickToHome}>トップページ</a></li>
      <li><a onClick={clickToEvent}>祭事・神事</a></li>
      <li><a onClick={clickToOmamori}>御守り</a></li>
      <li><a onClick={clickToGokitou}>御祈祷</a></li>
      <li><a onClick={clickToCaution}>来社時事項・問い合せ</a></li>
    </ul>
  </nav>
)}
    </>
  );
};

export default Header