import React from "react"
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";

type NewsData = {
    date?: string;
    content: string;
    text: string;
    a_text?: string;
    img?: string;
    img2?: string;
    link?: string;
  };

type Props = {
    scrollY: number;
};

export const News = ({ scrollY }: Props) => {
    const navigate = useNavigate(); // navigate 関数を初期化

    // ニュース項目をクリックしたときの処理とデータ渡し
    const handleNewsClick = (newsData: NewsData) => {
        navigate('/news', { state: { ...newsData } });
    }
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
      if (scrollY >= 300) {
        setFadeIn(true);
      }
    }, [scrollY]);

    return (
        <>
            <div className={`news ${fadeIn ? 'fade-in-news' : 'false-fade-in-news'}`}>
                <h2 >お知らせ</h2>
                <a  onClick={() => handleNewsClick({ 
                    date: "社務所（授与所①）対応時間　6:00～※17:00\n仮設授与所（授与所②）対応時間　9:00～16:00", 
                    content: "令和６年「元日の神社対応」　早朝御崎にお越しになる方へ" ,
                    text:"御朱印は社務所（授与所①）での対応のみになります。※当日の参拝者数を見て時間延長する場合がございます。\n元旦におきましては御崎一帯に初日の出を見にこられる方が非常に多いため神社周辺の交通が滞る可能性がございます。元日早朝、正月期間に当社境内に進入される車両につきましては、一時的に台数制限をかけさせていただく場合が有りますので、表示されている指示に従い、近隣住民の方々のご迷惑にならないようご配慮の程お願い申し上げます。\nまた参拝を目的として境内にお越しになられる方のために神社の駐車場はございますので、観光を目的として御崎にお越しになられる場合は原則赤穂市が公開している御崎周辺の無料の観光駐車場数箇所をご利用くださいますよう、深くお願い申し上げます。\n正月時の授与品等の在庫状況、緊急を要するお知らせは神社公式インスタグラムにて発信いたします。併せてご確認くださいませ",
                    a_text:"赤穂市が公開している御崎周辺の無料駐車場一覧マップ<クリック>",
                    link: "https://www.google.com/maps/d/u/0/viewer?mid=1Idsp-iNPsOmHxbYnxIGlQkYWjEpIWLE&ll=34.73087941489543%2C134.41181326926718&z=16",
                    img: "IMG_001.jpg",
                    })}>
                    <p>令和６年「元日の神社対応」<br/>早朝御崎にお越しになる方へ</p>
                    <p className="gray-text">令和６年１月１日（月）</p>
                </a>
                <a  onClick={() => handleNewsClick({ 
                    date: "令和６年１月１４日（日）午前７時", 
                    content: "とんど祭りについて" ,
                    text:"※とんどには『正月飾物』『古札・縁起物』以外の持ち込み、投入は禁止です。\n正月時の授与品等の在庫状況、緊急を要するお知らせは神社公式インスタグラムにて発信いたします。最新の情報も併せてご確認くださいませ。",
                    img: "IMG_0528.jpeg",
                    img2: "IMG_0529.jpeg",
                    })}>
                    <p>令和６年「とんど祭りについて」</p>
                    <p className="gray-text">令和６年１月１４日（日）午前７時</p>
                </a>
                <a  onClick={() => handleNewsClick({ 
                    content: "令和６年「厄除け御祈祷」のご案内" ,
                    text:"厄年について\n男性は、25歳（平成１２年生）・42歳（昭和５８年生）\n女性は、19歳（平成１８年生）・33歳（平成４年生）は『厄年』（やくどし）とされています。\nこの中で男性の42歳、女性の33歳は『大厄』（たいやく）といい万事を慎む事がよいとされています。\n年始より随時ご祈願致しておりますのでお気軽にお問い合わせ、ご予約くださいませ。（希望日の前日までに）",
                    img: "IMG_0534.jpeg",
                    })}>
                    <p>令和６年厄除け御祈祷のご案内</p>
                </a>
            </div>
        </>
    )
}