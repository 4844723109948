export const NextEvent = () => {
    return (
        <div className="next-event-wrap">
        <div className='nextpage-title-touka'>
            <img src='/images/saiji-touka.png' alt='text-omamori' />
            <p>さいし</p>
        </div>
        <div className="sub-text">
            <p>年中行事</p>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">１月</p>
                <div>
                    <p className="event-title">歳旦祭（さいたんさい・元旦）</p>
                    <img src='/images/saitansai.jpg' alt='event-img' className=" next-event-img"/>
                    <div className="text-after-image">
                    <p>新年を無事に迎えることができたことをお祝いし、<br />
                    皇室の弥栄、国の隆昌、氏子崇敬者の加護を祈る。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">１月</p>
                <div>
                    <p className="event-title">とんど祭り（１月中旬※第２日曜早朝）</p>
                    <img src='/images/IMG_3578.jpeg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>地域よっては左義長（さぎちょう）ともいう。<br/>
                        お正月飾りや古札、書き初めを笹等で作った枠の中に集め<br/>
                        忌火を点火させて氏子等で周りを囲み<br/>
                        五穀豊穣、商売繁盛、家内安全、無病息災等を願う。<br/>
                        火が少し弱まり朝日が昇る頃お宮で餅配りを行う。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">６月</p>
                <div>
                    <p className="event-title">輪越し祭り（６月３０日夕刻）</p>
                    <img src='/images/IMG_9316.jpeg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>またの名を茅の輪くぐり・夏越の大祓ともいう。<br/>
                        拝殿前に地域老人会の協力で奉製・設置された茅の輪の前で<br/>
                        神職が「大祓」奏上等の神事を執り行い<br/>
                        １月より積もった半年分の罪ケガレをお祓いする。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">７月</p>
                <div>
                    <p className="event-title">夏祭り（７月海の日　※本来の祭日は１３日）</p>
                    <img src='/images/IMG_2186.jpg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>現在は海の日に行われる、酷暑を乗り越え健康でいられるように祈るお祭
                        り。<br/>
                        昭和の時代では相撲大会が行われていた。<br/>
                        現在は奉献していただいた風鈴を拝殿に飾り<br/>
                        神社が用意した景品が当てる抽選大会も催す。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">１０月</p>
                <div>
                    <p className="event-title">秋祭り・例祭（１０月第二日曜　※ 本来の祭日は１３日）</p>
                    <img src='/images/IMG_9482.jpeg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>境内本殿斎庭にて神恩感謝し豊穣を氏子総代等とともに祈る。<br/>
                        当社秋祭りは、神事の一つである「獅子舞」が氏子の家を巡回し舞い厄を祓
                        う。<br/>
                        神事とは別に地域の子供屋台が地区内を巡回する。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">１１月</p>
                <div>
                    <p className="event-title">七五三詣（１１月中、元来は１５日）</p>
                    <img src='/images/hitigosan.jpg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>子供の成長を神前で喜び祝い、これからも健やかに育つことを祈る。<br/>
                        基本数え年で３、５、７歳になられるお子様のお詣りになる。<br/>
                        現在は満年齢でお越しになられることも多く当社は厳格に定めてはいない。<br/>
                        男の子が　３歳　５歳<br/>
                        女の子が　３歳　７歳　にお詣りし<br/>
                        ３歳が『髪置き』　５歳が『袴着』　７歳が『帯解』とそれぞれ儀式に名前
                        がある。</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="event-photo-layout bg-top">
            <div className="content-wrapper">
                <p className="rotated-text">１２月</p>
                <div>
                    <p className="event-title">しめ縄・しめ飾り奉製（１２月上旬から中旬にかけて）</p>
                    <img src='/images/IMG_0260.jpg' alt='event-img' className="next-event-img"/>
                    <div className="text-after-image">
                    <p>当社のしめ縄は１０月下旬に藁を集め乾燥させ<br/>
                        １２月に氏子地域の老人会の協力を仰ぎ、時間をかけて手作りで奉製する。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}