type Props = {
    scrollY: number;
  };

export const Access = ({ scrollY }: Props) => {
    return (
        <>
        <div className="access">
            <h2>交通・アクセス</h2>
            <span>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1639.5534021988894!2d134.40739438741141!3d34.72770213063734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3554f582db5e689d%3A0x4fcf6a22c747cccf!2z5LyK5ZKM6YO95q-U5aOy56We56S-!5e0!3m2!1sja!2sjp!4v1702571170768!5m2!1sja!2sjp"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="iframe"
                ></iframe>
                <div className="access-info">
                    <p className="moyori">
                    最寄り駅 : 播州赤穂駅<br />
                     『JR相生駅』-15分→『JR播州赤穂駅』-神姫バス15分→『御崎』-徒歩1分→『伊和都比売神社』<br />
                    </p>
                    <div>
                    <p className="text-lg">お車でお越しの場合</p>
                    <p className="text-sm access2">
                    当社境内の駐車可能台数は２０台弱になります。
                    参拝にお越しの方は境内駐車場をご利用ください。
                    境内と道路を挟んで市営の「観光駐車場」がございますので
                    観光でお越しの方はそちらにお停めいただきますようお願い申しあげます。
                    </p>
                    </div>
                    <div className="text-sm access2">
                    <p className="text-lg">ご注意</p>
                    ※境内にはお手洗いがございません。
                    きらきら坂道中、市営観光駐車場
                    にございます。<br />
                    </div>
                </div>
            </span>
        </div>
        </>
    )
}