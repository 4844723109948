import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const NextOmamori = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '33%', // デフォルトのコンテナ幅の1/3に相当するパディング
        swipeToSlide: true,
        touchThreshold: 10, // スワイプの感度を上げる
        autoplay: true, // 自動スライドを有効化
        autoplaySpeed: 3000, // スライド間の待機時間
        responsive: [
          {
            breakpoint: 767, // 767px以下の画面サイズの場合の設定
            settings: {
              centerPadding: '0%', // 画面幅が767px以下の場合はパディングを0にする
              // 他の必要な設定があればここに追加します。例えば:
              slidesToShow: 1, // 1つのスライドを表示
              centerMode: true, // センターモードを維持
            }
          }
        ]
      };

      const ohudaSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,  
        slidesToScroll: 1,
        centerMode: true,  // Set to false if you don't want any part of the adjacent slides to show
        centerPadding: '10%',  // Adjust or set to '0%' to show only the current slide
        swipeToSlide: true,
        touchThreshold: 10,
        autoplay: true,
        autoplaySpeed: 3000,
      };

      const omamori = [
        {
            "name":"健康お守り(朱)",
            "url": "omamori01.jpeg",
            "text": "500円",
        },
        {
            "name":"健康お守り(紫)",
            "url": "omamori02.jpeg",
            "text": "500円",
        },
        {
            "name":"厄除け守り(緑)",
            "url": "omamori03.jpeg",
            "text": "500円",
        },
        {
            "name":"姫守り(縁結び)",
            "url": "omamori04.jpeg",
            "text": "500円",
        },

        {
            "name":"勝守り(白)  ",
            "url": "omamori05.jpeg",
            "text": "500円",
        },
        {
            "name":"勝守り(黒)  ",
            "url": "omamori06.jpeg",
            "text": "500円",
        },
        {
            "name":"交通安全お守り",
            "url": "omamori07.jpeg",
            "text": "1000円",
        },
      ]
      const ohuda = [
        {
            "name":"神宮大麻(天照大神)",
            "url": "omamori08.jpeg",
            "text": "1000円",
        },
        {
            "name":"伊和都比売神社神札",
            "url": "omamori09.jpeg",
            "text": "500円",
        },
      ]
      const goshuin = [
        {
            "name":"通常御朱印",
            "url": "omamori10-1.jpeg",
            "text": "500円",
            "text2": "",
            "text3": "※書き置きのみ",
        },
        {
            "name":"特別御朱印（夕浪と老松）",
            "url": "omamori11.jpeg",
            "text": "700円",
            "text2": "※書き置きのみ",
        },
        {
            "name":"限定御朱印",
            "url": "omamori12-1.jpg",
            "text": "800円",
            "text2": "（月毎デザイン変更）",
            "text3": "※書き置きのみ",
        },
      ]
      const shougatu = [
        {
            "name":"幸先矢(破魔矢) ",
            "url": "omamori13.jpeg",
            "text": "1500円",
            "text2": "(※約60cm)",
        },
        {
            "name":"干支幸先祈願符",
            "url": "omamori14.jpg",
            "text": "1200円",
        },
      ]

      return (
        <>
        <div className='nextpage-title-touka'>
            <img src='/images/omamori-touka.png' alt='text-omamori' />
            <p>おまもり</p>
        </div>
        <Slider {...settings} className='next-omamori-wrap'>
            {omamori.map((i) => (
                <div className='event' key={i.url}>
                <span className='event-content01'>
                    <h2>{i.name}</h2>
                    <img src={`/images/${i.url}`} alt="icon" className="omamori-img"/>
                    <span className='under-text'>
                    <p>{i.text}</p>
                    </span>
                </span>
                </div>
            ))}
        </Slider>
        <div className='nextpage-title-touka'>
            <img src='/images/ohuda-touka.png' alt='text-omamori' />
            <p>おふだ</p>
        </div>
        <Slider {...ohudaSettings} className='next-omamori-wrap'>
            {ohuda.map((i) => (
                <div className='event' key={i.url}>
                <span className='event-content01'>
                    <h2>{i.name}</h2>
                    <img src={`/images/${i.url}`} alt="icon" className="ohuda-img"/>
                    <span className='under-text'>
                    <p>{i.text}</p>
                    </span>
                </span>
                </div>
            ))}
        </Slider>
        <div className='nextpage-title-touka'>
            <img src='/images/gosyuin-touka.PNG' alt='text-omamori' />
            <p className='margin'>ごしゅいん</p>
        </div>
        <Slider {...settings} className='next-omamori-wrap'>
            {goshuin.map((i) => (
                <div className='event' key={i.url}>
                <span className='event-content01'>
                    <h2>{i.name}</h2>
                    <img src={`/images/${i.url}`} alt="icon" className="omamori-img"/>
                    <span className='under-text'>
                    <p>{i.text}</p>
                    <p>{i.text2}</p>
                    <p>{i.text3}</p>
                    </span>
                </span>
                </div>
            ))}
        </Slider>
        <div className='nextpage-title-touka'>
            <img src='/images/gentei-touka.png' alt='text-omamori'/>
            <p className='margin'>げんていじゅよ</p>
        </div>
        <Slider {...ohudaSettings} className='next-omamori-wrap'>
            {shougatu.map((i) => (
                <div className='event' key={i.url}>
                <span className='event-content01'>
                    <h2>{i.name}</h2>
                    <img src={`/images/${i.url}`} alt="icon" className="omamori-img"/>
                    <span className='under-text'>
                    <p>{i.text}</p>
                    <p>{i.text2}</p>
                    </span>
                </span>
                </div>
            ))}
        </Slider>
        </>
    )
}