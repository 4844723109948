import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

type Props = {
  scrollY: number;
};

export const SaijiAndShinji = ({ scrollY }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '33%', // 768px以上の場合のコンテナ幅の1/3に相当するパディング
    swipeToSlide: true,
    touchThreshold: 10,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // 768px以下の画面サイズ
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,  
          slidesToScroll: 1,
          centerMode: true,  // Set to false if you don't want any part of the adjacent slides to show
          centerPadding: '0%',  // Adjust or set to '0%' to show only the current slide
          swipeToSlide: true,
          touchThreshold: 10,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      }
      // 他のブレークポイント設定があればここに追加する
    ]
  };

  const navigate = useNavigate();
  const clickToEvent = () => {
    navigate('/next-event');
  };
  const clickToOmamori = () => {
    navigate('/omamori');
  };
  const clickToGokitou = () => {
    navigate('/gokitou');
  };

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (scrollY >= 2667) {
      setAnimate(true); // scrollYが2667以上ならアニメーションを開始
    }
  }, [scrollY]);

  return (
    <Slider {...settings} className={`event-wrap ${animate ? 'animate' : ''}`}>
      <div className='event'>
        <p className=''>Festivals and Rituals</p>
        <span className='event-content01'>
          <h2>祭事・神事</h2>
          <a onClick={clickToEvent} className='onClick-a'>
            <img src="/images/saiji.jpeg" alt="icon" className="event-img"/>
          </a>
          <span className='under-text'>
            <p>『一年における祭礼行事』</p>
          </span>
        </span>
      </div>
      <div className='event'>
        <p>Shinto Prayer Ritual</p>
        <span className='event-content02'>
          <h2>御祈祷</h2>
          <a onClick={clickToGokitou} className='onClick-a'>
            <img src="/images/IMG_1714.jpeg" alt='icon' className="event-img"/>
          </a>
          <span className='under-text'>
            <p>『人生の節目における儀礼』</p>
          </span>
        </span>
      </div>
      <div className='event'>
        <p>Omamori and Goshuin</p>
        <span className='event-content03'>
          <h2>御守・御朱印</h2>
          <a onClick={clickToOmamori} className='onClick-a'>
            <img src="/images/IMG_0530.jpeg" alt='icon' className="event-img"/>
          </a>
          <span className='under-text'>
            <p>『神様との縁結び』</p>
          </span>
        </span>
      </div>
    </Slider>
  );
};