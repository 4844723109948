export const NextGokitou = () => {
    return (
        <div className="next-gokitou">
        <div className='nextpage-title-touka'>
            <img src='/images/gokitou-touka.png' alt='text-omamori' />
            <p>ごきとう</p>
        </div>
            <span>
                <img src="/images/IMG_9805.jpg" alt='icon' className="nextgokitou-img first-element"/>
                <p className="second-element">
                    ご予約ご希望日の前日までの電話予約のみで対応させていただいております。<br/>
                    <br/>『厄除け』・『家内安全』・『商売繁盛』・『安産祈願』・『お車のお祓い』・『初宮詣』・『七五三』等随時承っております。
                    <br/><br/>お電話でご連絡いただきましたら、当日ご利用の交通機関をおたずねしますのでお車の場合、台数等おっしゃっていただきましたら、境内にて駐車スペースを事前に確保させていただきます。
                    <br/><br/>こちらの都合になりますが（複数の台数の確保ができない場合もございますので予めご了承ください。）
                </p>
            </span>
            <span>
                <p className="second-element gokitou01">
                    御祈祷&emsp;初穂料&emsp;※5000円（一律）<br/>
                    御祈祷&emsp;所要時間&emsp;20分～30分程 <br/>
                    ※（例）家内安全は１家に対して&emsp;初穂料5000円<br/>
                    商売繁盛は１社に対して&emsp;初穂料5000円
                </p >
                <img src="/images/IMG_9815.jpg" alt='icon' className="nextgokitou-img"/>
            </span>
        </div>
    )
}