import { useLocation } from 'react-router-dom'; // useLocation をインポート

export const NextNews = () => {
    const location = useLocation(); // location オブジェクトにアクセス
    const newsData = location.state; // state として渡されたデータにアクセス

    const convertNewlines = (text: string) => {
        // テキストを \n で分割し、React要素の配列として返す
        return text.split('\n').map((line, index, array) => (
            index === array.length - 1 ? line : <>{line}<br /></>
        ));
    };

    return(
        <div className='wrap-detail-news'>
            <div className="news-detail">
                {/* state として渡されたニュース内容を表示 */}
                <p className='news-dtl-data'>{newsData.date}</p>
                <p className='news-dtl-title'>{newsData.content}</p>
                <p className='news-dtl-text'>{convertNewlines(newsData.text)}</p>
                <a href={`${newsData.link}`}>{newsData.a_text}</a>
                {newsData.img && (
                    <img src={`/images/${newsData.img}`} alt='news' />
                )}
                {newsData.img2 && (
                    <img src={`/images/${newsData.img2}`} alt='news' />
                )}
            </div>
        </div>
    )
}