import React from 'react';
import { useEffect, useState } from 'react';

type Props = {
  scrollY: number;
};

const Home = ({ scrollY }: Props) => {
  const homeImage = [
    "/images/home-bg01.jpeg",
    "/images/home-bg02.jpeg",
    "/images/home-bg03.jpeg",
    "/images/IMG_002.jpeg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // 画像のインデックスを次に進める
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % homeImage.length);
    }, 10000);

    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => clearInterval(intervalId);
  }, []);

  // scrollYが333以上の場合に適用するスタイルを計算
  const shinmonStyle = scrollY > 300 ? { opacity: 0, transition: 'opacity 1s ease-out' } : {};
  const hiddenText = scrollY > 300 ? { opacity: 100, transition: 'opacity 1s ease-in' } : {opacity: 0, transition: 'opacity 1s ease-out' };

  return (
    <>
      <div className="top-css">
        <div className="home">
          <img src={homeImage[currentImageIndex]} alt='icon' className="home-bg"/>
          <span style={shinmonStyle}>
            <img src="/images/shinmon.png" alt="shinmon" className="shinmon-img"/>
            <div className="jinji-name">
              <p className="bansyu">赤穂御崎</p>
              <h1>伊和都比売神社</h1>
            </div>
          </span>
          <div style={hiddenText} className='hidden-text'>
            <p>#鳥居越しに海が見える神社</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;