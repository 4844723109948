export const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="footer01">
                    <p>伊和都比売神社</p>
                    <span className="footer02">
                        <p>お問い合わせ</p>
                        <p>0791-42-3547</p>
                    </span>
                </div>
                <div className="footer01">
                    <p>〒678-0215 兵庫県赤穂市御崎１</p>
                    <p>©2023 伊和都比売神社 All Rights Reserved.</p>
                </div>
            </div>
        </>
    )
}