import React from 'react'

const Caution = () => {
  return (
    <div className='caution1'>
        <h1>来社時注意事項</h1>
        <span>
            <h2>当神社参拝にお越しの方へご協力のお願い</h2>
            <br/>
            <p>
            ①お車でお越しの方へ<br/>
            車での境内進入時は最徐行でお願いいたします。<br/>
            <br/>
            ②境内の駐停車について<br/>
            神社正式参道、神社祭式による正中（正面中央）にあたる
            境内鳥居正面での全車両（自転車、バイク含む）の駐停車は
            固くお断りしております。見つけ次第注意させていただきます。
            またこれらを破りSNS等にてそれらの写真を投稿することも
            当然ながらご遠慮申し上げております。
            何卒ご理解とご協力のほどお願い申し上げます。
            <br/>
            <br/>
            ③境内での撮影について<br/>
            人物の個人撮影等につきましては周りの方のご迷惑にならない
            様に撮影していただければ何も問題ございませんのでご自由にお
            撮り下さい。
            企業様、メディア、フォトウェディング、PV撮影、業者依頼の
            長時間の撮影等をご検討の場合は予め予定がお決まり次第必ず
            神社にご連絡下さいますよう願い申し上げます。
            ※ドローンでの撮影は個人業者問わず必ずご相談ください。
            <br/>
            <br/>
            ④お手洗いについて<br/>
            境内にはお手洗いがございません。
            その際は<br/>
            ・神社境内入り口の向かい10m先の市営観光駐車場に一箇所。
            ・境内から続くきらきら坂道中に一箇所。
            をご利用くださいませ。
            <br/>
            <br/>
            ⑤廃棄物の処分について
            廃棄物、タバコの吸い殻等は境内に捨てる事なく
            必ず各自でお持ち帰りいただきご処分お願いいたします。
            そのような行為が発覚した際は然るべき対応を取らせていただきます。
            <br/>
            <br/>
            <h2 className='caution2'>お問い合わせ</h2>
            伊和都比売神社　社務所<br/>
            TEL    0791-42-3547<br/>
            FAX    0791-42-5080<br/>
            <br/>
            御祈祷のご予約や御朱印、祭事についてのお問い合わせは、トラブルを考慮してお電話にて対応させていただいております。
            社務所対応時間外でもできる範囲で対応させていただいておりますので、お気軽にご連絡下さいませ。
            尚お電話が繋がらない場合は留守番等にて、お名前とご用件を必ずお伝えくださいますよう、お願い申し上げます。
            （間違い電話、迷惑電話等と判けるため）
            <br/>
            <br/>
            また当神社の授与品（お守り、御朱印）は商品ではございません。
            授与品の第三者による売買、所謂転売等その一切を禁止しております。
            発覚次第、然るべき対応を取らせていただく場合がございますのでご承知ください。
            </p>
        </span>
    </div>
  )
}

export default Caution