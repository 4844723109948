import { useState, useEffect } from 'react';

// カスタムフックの定義
export const useScrollPosition = () => {
  // スクロール位置の状態
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // スクロールイベントをハンドリングする関数
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // スクロールイベントリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // スクロール位置を返す
  return scrollY;
};