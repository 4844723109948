import { useState, useEffect } from "react";

type Props = {
    scrollY: number;
  };

export const Sanpai = (props: Props) => {
    const scrollY = props
    const [animate, setAnimate] = useState(false);
    const [click, setClick] = useState("1");

    useEffect(() => {
      if (props.scrollY >= 667) {
        setAnimate(true); // scrollYが667以上ならアニメーションを開始
      }
    }, [scrollY]);
    return (
        <>
        <div className={animate ? "sanpai animate" : "sanpai"}>
            <div className="content-sanpai">
                <h2>参拝のご案内</h2>
                <span className="content-layout02">
                    <img src="/images/img_001.jpg" alt="keidai" className="keidai"/>
                </span>
                <span className="content-layout03">
                    <span className="content-layout01">
                        <p className="text-syamu">社務所対応時間</p>
                        <p className="open-time">9:00~12:00<br/>
                            13:00~16:00
                        </p>
                        <p className="text-insta">※社務所対応不可日は神社公式インスタグラムにて</p>
                    </span>
                </span>
                <span className="content-layout04">
                    <span className="content-layout05">
                        <div className="content-layout07">
                            <a onClick={()=>{setClick("1")}} className={click === "1" ? "onclick-07" : ""}>御本殿</a>
                            <a onClick={()=>{setClick("2")}} className={click === "2" ? "onclick-07" : ""}>摂末社</a>
                        </div>
                        <div className="content-layout06">
                        {click === "1" && (
                        <div>
                            <p>主祭神　伊和都比売大神　（いわつひめおおかみ）</p>
                            <p>御社名の由来に起因する御祭神。延喜式神名帳にもその御名を連ね、御崎の地名から『御崎明神』とも称せられている。</p>
                        </div>
                        )}
                        {click === "2" && (
                        <div>
                            <p>恵比須社（南側）御祭神　恵比須神（えびすのかみ）</p>
                            <p>金比羅社（北側）御祭神　大物主神（おおものぬしのかみ）</p>
                        </div>
                        )}
                        </div>
                    </span>
                </span>
            </div>
        </div>
        </>
    );
}